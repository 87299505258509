<template>
  <div :style="this.CSS" :class="CSSClasses" v-show="!isHidden">
    <el-checkbox-group
      @change="change"
      :disabled="readonly"
      :name="name"
      :size="size"
      v-model="localModel"
    >
        <el-checkbox
          v-for="(item, index) in options"
          :key="index"
          :label="item.value"
          :is="type === 'basic' ? 'el-checkbox' : 'el-checkbox-button'"
        >{{ item.name }}
      </el-checkbox>
    </el-checkbox-group>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
export default {
  name: 'a-checkbox',
  mixins: [mixin, VisibleMixin],
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  props: {
    model: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    type: {
      type: String,
      description: 'Тип',
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },
    size: {
      type: String,
      description: 'Размер',
      editor: 'Size'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    defaultCheck: {
      type: Boolean,
      description: 'По умолчанию выбран',
      frozen: true
    },
    options: {
      type: Array,
      default () {
        return [{ name: 'Лэйбл', value: 'Значение' }]
      },
      editor: 'Options'
    }
  },
  data () {
    return {
      localModel: this.$attrs.value || []
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    options: {
      handler (value) {
        if (!value[0].default) {
          this.$emit('change-property', { name: 'defaultCheck', value: false })
        }
      },
      deep: true
    }
  },
  created () {
    // defaultCheck для сохранения старого функционала
    if (this.defaultCheck) {
      this.localModel = [this.options[0].value]
    }

    let defaultCheckox = this.options.filter(item => item.default).map(item => item.value)
    if (defaultCheckox.length) {
      this.localModel = [...defaultCheckox]
    }
  },
  mounted () {
    if (this.localModel.length) {
      this.$emit('input', this.localModel.toString())
    }
  },
  methods: {
    change (event) {
      this.$emit('input', event.toString())
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
